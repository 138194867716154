import { Components, Theme } from '@mui/material';
import { Autocomplete } from '@shared/theme/components/Autocomplete';
import { Backdrop } from '@shared/theme/components/Backdrop';
import { Button } from '@shared/theme/components/Button';
import { ButtonBase } from '@shared/theme/components/ButtonBase';
import { Checkbox } from '@shared/theme/components/Checkbox';
import { CircularProgress } from '@shared/theme/components/CircularProgress';
import { CssBaseline } from '@shared/theme/components/CssBaseline';
import { DataGrid } from '@shared/theme/components/DataGrid';
import { Dialog } from '@shared/theme/components/Dialog';
import { FormControl } from '@shared/theme/components/FormControl';
import { FormControlLabel } from '@shared/theme/components/FormControlLabel';
import { FormLabel } from '@shared/theme/components/FormLabel';
import { IconButton } from '@shared/theme/components/IconButton';
import { Link } from '@shared/theme/components/Link';
import { List } from '@shared/theme/components/List';
import { OutlinedInput } from '@shared/theme/components/OutlinedInput';
import { Paper } from '@shared/theme/components/Paper';
import { Popover } from '@shared/theme/components/Popover';
import { Select } from '@shared/theme/components/Select';
import { Switch } from '@shared/theme/components/Switch';
import { Tab } from '@shared/theme/components/Tab';
import { TabPanel } from '@shared/theme/components/TabPanel';
import { ToggleButton } from '@shared/theme/components/ToggleButton';
import { Tooltip } from '@shared/theme/components/Tooltip';
import { Typography } from '@shared/theme/components/Typography';

export const components: Components<Omit<Theme, 'components'>> = {
  MuiCssBaseline: CssBaseline,
  MuiTypography: Typography,
  MuiButtonBase: ButtonBase,
  MuiButton: Button,
  MuiIconButton: IconButton,
  MuiLink: Link,
  MuiList: List,
  MuiOutlinedInput: OutlinedInput,
  MuiPaper: Paper,
  MuiPopover: Popover,
  MuiSelect: Select,
  MuiTab: Tab,
  MuiTooltip: Tooltip,
  MuiSwitch: Switch,
  MuiCheckbox: Checkbox,
  MuiAutocomplete: Autocomplete,
  MuiFormLabel: FormLabel,
  MuiFormControlLabel: FormControlLabel,
  MuiBackdrop: Backdrop,
  MuiCircularProgress: CircularProgress,
  MuiFormControl: FormControl,
  MuiDataGrid: DataGrid,
  MuiToggleButton: ToggleButton,
  MuiTabPanel: TabPanel,
  MuiDialog: Dialog,
  MuiPopover: Popover,
};
