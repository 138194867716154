import { createStore, createEvent, merge, split, sample } from 'effector';
import { signout, $pathname, historyPush } from '@features/common';

function createDetailBag(newEntity: object) {
  const changedDetailVisibility = createEvent<boolean>();
  const changeMode = createEvent<string>();
  const detailSubmitted = createEvent<any>();
  const addClicked = createEvent<void>();
  const open = createEvent<number | string>();
  const openViaUrl = createEvent<number | string>();

  const $entityId = createStore<null | number | string>(null);
  const $isDetailOpen = createStore<boolean>(false);
  const $mode = createStore<string>('view');
  const $opened = createStore<any>(newEntity);

  const detailClosed = changedDetailVisibility.filterMap((visibility) => {
    if (!visibility) return visibility;

    return undefined;
  });

  const entityApi = split(
    sample($opened, detailSubmitted, (opened, payload: {}) => ({
      ...opened,
      ...payload,
    })),
    {
      create: ({ id }) => !id,
      update: ({ id }) => Boolean(id),
    }
  );

  $isDetailOpen
    .on(changedDetailVisibility, (_, visibility) => visibility)
    .on(open, () => true)
    .on(addClicked, () => true)
    .reset(signout);

  $mode
    .on(changeMode, (_, mode) => mode)
    .on(addClicked, () => 'edit')
    .on(merge([detailClosed, open, openViaUrl]), () => 'view')
    .reset(signout);

  $opened.on(merge([addClicked, detailClosed]), () => newEntity).reset(signout);

  $entityId.on(openViaUrl, (_, id) => id).reset([signout, detailClosed]);

  sample({
    clock: addClicked,
    source: $pathname,
    fn: (pathname: string) => `../${pathname.split('/')[1]}`,
    target: historyPush,
  });

  sample({
    clock: openViaUrl,
    source: { pathname: $pathname, id: $entityId },
    fn: ({ pathname, id }: { pathname: string; id: number | null }): string => {
      if (!id) {
        return `../${pathname.split('/')[1]}`;
      }

      return pathname.split('/')[1]
        ? `../${pathname.split('/')[1]}/${id.toString()}`
        : '.';
    },
    target: historyPush,
  });

  sample({
    source: $entityId,
    fn: (entityId) => Boolean(entityId),
    target: $isDetailOpen,
  });

  return {
    changedDetailVisibility,
    changeMode,
    detailSubmitted,
    addClicked,
    open,
    detailClosed,
    entityApi,
    openViaUrl,

    $isDetailOpen,
    $mode,
    $opened,
  };
}

export { createDetailBag };
