import { useGate, useUnit } from 'effector-react';
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  FormLabel,
  TextField,
  CircularProgress,
} from '@mui/material';
import { Listbox } from '@features/effector-form/components/listbox';
import { withControl } from '@features/effector-form/hoc/withControl';
import { PropertyTypesControlGate, propertyTypesQuery } from '../model';
import { Props } from '../types';

export const PropertyTypeControl = withControl<Props>(
  ({
    value,
    setValue,
    error,
    label,
    required,
    readOnly,
    disabled,
    withClientType = false,
    disableClearable = false,
    sx = {},
    mode,
    onlyActual = false,
    parentAreaId = null,
  }) => {
    useGate(PropertyTypesControlGate, { withClientType, mode, onlyActual, parentAreaId });

    const { data, pending } = useUnit(propertyTypesQuery);

    const val =
      value !== ''
        ? data?.find((item) =>
            typeof value === 'object' ? item.id === value?.id : item.id === value
          )
        : null;

    const options = !data ? [] : data;
    const list = onlyActual && !parentAreaId ? [] : options;

    return (
      <FormControl required={required} disabled={disabled} sx={sx}>
        {label && <FormLabel>{label}</FormLabel>}
        <Autocomplete
          disableClearable={disableClearable}
          loading={pending}
          value={!data ? null : val || null}
          options={list}
          onChange={(event: any, newValue: any) => {
            setValue(newValue || null);
          }}
          ListboxComponent={Listbox}
          renderInput={(params): JSX.Element => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                readOnly,
                endAdornment: readOnly ? null : (
                  <>
                    {pending && <CircularProgress size={20} color="inherit" />}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          readOnly={readOnly}
          disabled={disabled}
        />
        <FormHelperText error>{error}</FormHelperText>
      </FormControl>
    );
  }
);
