/**
 * Этот компонент реализован крайне плохо, он был полностью скопирован с документации mui
 * */
// @ts-nocheck
import * as React from 'react';
import { forwardRef, memo } from 'react';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import BlurCircularIcon from '@mui/icons-material/BlurCircular';
import ClearAllOutlinedIcon from '@mui/icons-material/ClearAllOutlined';
import CropFreeOutlinedIcon from '@mui/icons-material/CropFreeOutlined';
import ElevatorOutlinedIcon from '@mui/icons-material/ElevatorOutlined';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import MeetingRoomOutlinedIcon from '@mui/icons-material/MeetingRoomOutlined';
import { TreeItemContentProps, TreeItem, useTreeItem } from '@mui/x-tree-view';
import { openObject } from '@features/effector-form/controls/object/model';
import { ObjectsTreeItemType } from '@features/effector-form/controls/object/types/common';
import Apartment from '@img/apartment.svg';
import Building from '@img/building.svg';
import Complex from '@img/complex.svg';
import Office from '@img/office.svg';
import Pantry from '@img/pantry.svg';
import ParkingLot from '@img/parking-lot.svg';
import ParkingPlace from '@img/parking-place.svg';
import ParkingZone from '@img/parking-zone.svg';
import { useStyles } from './styles';

const CustomContent = forwardRef(
  (
    {
      classes,
      className,
      label,
      nodeId,
      icon: iconProp,
      expansionIcon,
      displayIcon,
      onClick,
    }: TreeItemContentProps,
    ref
  ) => {
    const {
      disabled,
      expanded,
      selected,
      focused,
      handleExpansion,
      handleSelection,
      preventSelection,
    } = useTreeItem(nodeId);

    const icon = iconProp || expansionIcon || displayIcon;

    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      preventSelection(event);
    };

    const handleExpansionClick = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      handleExpansion(event);
    };

    const handleSelectionClick = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      handleSelection(event);
      onClick();
    };

    return (
      <div
        className={clsx(className, classes.root, {
          [classes.expanded]: expanded,
          [classes.selected]: selected,
          [classes.focused]: focused,
          [classes.disabled]: disabled,
        })}
        onMouseDown={handleMouseDown}
        ref={ref as React.Ref<HTMLDivElement>}
      >
        <div onClick={handleExpansionClick} className={classes.iconContainer}>
          {icon}
        </div>
        <Typography
          onClick={handleSelectionClick}
          component="div"
          className={classes.label}
        >
          {label}
        </Typography>
      </div>
    );
  }
);

export const ObjectsTreeItem = memo(
  ({ object, ...props }: { object: ObjectsTreeItemType }) => {
    const classes = useStyles();

    const handleItemClick = () => {
      openObject(object.guid);
    };

    const renderIcon = () => {
      switch (object.type) {
        case null:
          return <ClearAllOutlinedIcon className={classes.objectsItem__icon} />;
        case 'complex':
          return <Complex />;
        case 'building':
          return <Building />;
        case 'parking_lot':
          return <ParkingLot />;
        case 'parking_zone':
          return <ParkingZone />;
        case 'parking_place':
          return <ParkingPlace />;
        case 'entrance':
          return <MeetingRoomOutlinedIcon className={classes.objectsItem__icon} />;
        case 'floor':
          return <LayersOutlinedIcon className={classes.objectsItem__icon} />;
        case 'elevator':
          return <ElevatorOutlinedIcon className={classes.objectsItem__icon} />;
        case 'apartment':
          return <Apartment />;
        case 'room':
          return <CropFreeOutlinedIcon className={classes.objectsItem__icon} />;
        case 'pantry':
          return <Pantry />;
        case 'office':
          return <Office />;
        default:
          return <BlurCircularIcon className={classes.objectsItem__icon} />;
      }
    };

    const labelContent = (
      <div className={classes.objectsItem__title}>
        {renderIcon()}
        <span className={classes.objectsItem__titleText}>{object.title}</span>
      </div>
    );

    return (
      <TreeItem
        ContentComponent={CustomContent}
        classes={{
          group: classes.objectsItem_group,
          selected: classes.objectsItem_selected,
          iconContainer: classes.objectsItem_arrow,
        }}
        nodeId={object.guid}
        label={labelContent}
        onClick={handleItemClick}
        {...props}
      />
    );
  }
);
