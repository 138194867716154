import ru from 'date-fns/locale/ru';
import { FormControl, FormHelperText, FormLabel } from '@mui/material';
import { CalendarToday } from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { withControl } from '@features/effector-form/hoc/withControl';
import { theme } from '@shared/theme';

const CalendarIcon = () => <CalendarToday sx={{ color: theme.palette.grey[700] }} />;

export const MonthControl = withControl(
  ({ value, setValue, error, label, required, readOnly, disabled }) => (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
      <FormControl required={required} disabled={disabled}>
        <FormLabel>{label}</FormLabel>
        <DatePicker
          views={['month']}
          openTo="month"
          value={value}
          readOnly={readOnly}
          disabled={disabled}
          slots={{
            openPickerIcon: CalendarIcon,
          }}
          onChange={(newValue) => {
            setValue(newValue);
          }}
        />
        <FormHelperText error>{error}</FormHelperText>
      </FormControl>
    </LocalizationProvider>
  )
);
