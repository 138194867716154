import { Components } from '@mui/material';

export const Button: Components['MuiButton'] = {
  defaultProps: {
    disableElevation: true,
  },
  styleOverrides: {
    root: {
      textTransform: 'none',
      padding: '8px 16px'
    },
  },
  variants: [
    {
      props: { size: 'medium' },
      style: {
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        height: '40px',
        boxSizing: 'border-box',
      },
    },
  ],
};
