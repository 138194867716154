import { Components } from '@mui/material';

export const Paper: Components['MuiPaper'] = {
  defaultProps: {
    elevation: 1,
  },
  styleOverrides: {
    root: {
      borderRadius: 8
    }
  },
};
