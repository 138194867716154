import { Components } from '@mui/material';

export const DataGrid: Components['MuiDataGrid'] = {
  styleOverrides: {
    root: {
      cursor: 'pointer',
      border: 0,
      '& .super-app-theme--header': {
        color: '#7D7D8E',
        padding: 5,
      },
      '& .MuiDataGrid-cell, & .MuiDataGrid-columnHeaders': {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
      },
      '& .row-selection.MuiDataGrid-row.Mui-selected': {
        backgroundColor: 'transparent',
      },
      '& .row-selection.MuiDataGrid-row.Mui-selected:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
      '& .row-selection.Mui-custom-opened': {
        backgroundColor: 'rgb(225, 235, 255)',
      },
      '& .row-selection.Mui-custom-opened.MuiDataGrid-row.Mui-selected': {
        backgroundColor: 'rgb(225, 235, 255)',
      },
      '& .row-selection.Mui-custom-opened:hover': {
        backgroundColor: 'rgb(225, 235, 255)',
      },
      '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
        outline: 'none',
      },
      '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within': {
        outline: 'none',
      },
      '& .MuiDataGrid-row.Mui-selected, & .MuiDataGrid-row.Mui-selected:hover': {
        background: 'rgb(225, 235, 255)',
      },
    },
  },
};
