import { useGate, useUnit } from 'effector-react';
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  FormLabel,
  TextField,
  CircularProgress,
} from '@mui/material';
import { Listbox } from '@features/effector-form/components/listbox';
import { Statuses } from '@features/effector-form/controls/rent-status-controls/types';
import { withControl } from '@features/effector-form/hoc/withControl';
import { RentStatusesControlGate, rentStatusesQuery } from '../model';
import { StatusProps } from '../types';

export const RentStatusesControl = withControl<StatusProps>(
  ({
    value,
    setValue,
    error,
    label,
    required,
    readOnly,
    disabled,
    mode = 'registry',
  }) => {
    useGate(RentStatusesControlGate, { mode });

    const { data, pending } = useUnit(rentStatusesQuery);

    return (
      <FormControl required={required} disabled={disabled}>
        <FormLabel>{label}</FormLabel>
        <Autocomplete
          multiple
          loading={pending}
          value={!data ? [] : data.filter((item) => value.indexOf(item.id) > -1)}
          options={!data ? [] : data}
          onChange={(_: unknown, newValue: any) => {
            setValue(newValue.map((item: Statuses) => item.id));
          }}
          ListboxComponent={Listbox}
          renderInput={(params): JSX.Element => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                readOnly,
                endAdornment: readOnly ? null : (
                  <>
                    {pending && <CircularProgress size={20} color="inherit" />}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          readOnly={readOnly}
          disabled={disabled}
        />
        <FormHelperText error>{error}</FormHelperText>
      </FormControl>
    );
  }
);
