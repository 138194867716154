import { createEffect, sample } from 'effector';
import { createGate } from 'effector-react';
import { cache, createQuery, localStorageCache } from '@farfetched/core';
import { signout } from '@features/common';
import { buildingApi } from './api';
import {
  BuildingControlProps,
  BuildingRequest,
  BuildingResponse,
} from './types';

export const BuildingControlGate = createGate<BuildingControlProps>();

export const fxGetBuildings = createEffect<
  BuildingRequest,
  BuildingResponse,
  Error
>().use(buildingApi.getBuildings);

export const buildingQuery = createQuery({
  name: 'building',
  effect: fxGetBuildings,
  mapData: ({ result, params }) =>
    result.buildings.map((building) => ({
      id: building.building.id,
      title: building.building.title,
      complexId: building.complex.id,
    })),
});

cache(buildingQuery, {
  adapter: localStorageCache({ maxAge: '5m' }),
  purge: signout,
});

/** Для запроса не требуется передача параметра complex_id, т.к. фильтрация осуществляется
 * на фронте из общего списка по массиву id комплексов  */
sample({
  clock: BuildingControlGate.state,
  fn: () => ({
    complex_id: undefined,
  }),
  target: buildingQuery.start,
});
