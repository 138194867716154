import { Button, ButtonGroup } from '@mui/material';
import i18n from '@shared/config/i18n';

const { t } = i18n;

export const FastDateSetter = ({
  onChange,
  value,
  disablePadding = false,
  size = 'large',
  today = t('TODAY'),
  tomorrow = t('TOMORROW'),
  plusDay = t('+1DAY'),
  ...props
}) => {
  const defineToday = () => new Date();

  const addDayToDate = (date) => new Date(new Date().setDate(date.getDate() + 1));

  return (
    <ButtonGroup
      style={{ paddingBottom: disablePadding ? 0 : 18 }}
      variant="text"
      size={size}
      fullWidth
      {...props}
    >
      <Button color="primary" onClick={() => onChange(defineToday())}>
        {today}
      </Button>
      <Button color="primary" onClick={() => onChange(addDayToDate(defineToday()))}>
        {tomorrow}
      </Button>
      <Button color="primary" onClick={() => onChange(addDayToDate(value))}>
        {plusDay}
      </Button>
    </ButtonGroup>
  );
};
