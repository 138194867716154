import { Components } from '@mui/material';

export const Typography: Components['MuiTypography'] = {
  defaultProps: {
    gutterBottom: false,
    variantMapping: {
      h1: 'h1',
      h2: 'h1',
      h3: 'h1',
      h4: 'h1',
      h5: 'h1',
      h6: 'h1',
      subtitle1: 'h1',
      subtitle2: 'h1',
      body1: 'h1',
      body2: 'h1',
    },
  },
  variants: [
    {
      props: { variant: 'h1' },
      style: {
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        height: '40px',
        boxSizing: 'border-box',
      },
    },
  ],
};
