import 'react-datepicker/dist/react-datepicker.css';
import { createRoot } from 'react-dom/client';
import * as Sentry from "@sentry/react";
import App from './components/App';
import { initializeFirebase } from './configs/firebase';
import './index.scss';
import './reboot.min.css';
import './shared/config/i18n';

Sentry.init({
  dsn: "https://335dc438405db02b29eb00e5b53a0280@o4507683609313280.ingest.de.sentry.io/4507683613179984",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = createRoot(document.getElementById('root')!);
root.render(<App />);

initializeFirebase();

if (module.hot) module.hot.accept();
