import { api } from '@api/api2';
import { GetCardRequest, UpdateCardRequest } from '@features/objects/types/card';
import { CreateObjectRequest } from '@features/objects/types/creation';
import {
  DeleteObjectRequest,
  DeleteObjectsRequest,
} from '@features/objects/types/delete';
import {
  GetDocsPayload,
  UploadDocumentPayload,
  ExportDocPayload,
  DocumentsPayload,
} from '@features/objects/types/docs';
import {
  GetObjectsRequest,
  GetExpandedTreePayload,
} from '@features/objects/types/object';
import { SearchObjectsPayload } from '@features/objects/types/objects-search';
import { GetObjectsTableRequest } from '@features/objects/types/objectsTable';
import { PropertyTypesPayload } from '@features/objects/types/property-types';
import { UpdateRentPayload, GetRentPayload } from '@features/objects/types/rent';
import {
  CreateRulePayload,
  GetRulePayload,
  UpdateRulePayload,
  ExportRulePayload,
} from '@features/objects/types/rules';
import { GetSettingsRequest, Settings } from '@features/objects/types/settings';

/** Фильтры */
const getObjects = (payload: GetObjectsRequest) =>
  api.v4('get', 'area/objects/tree', payload);

/** Получение таблицы дочерних объектов */
const getObjectsTable = (payload: GetObjectsTableRequest) =>
  api.v4('get', 'area/objects/list', payload);

/** Получение развернутого дерева объектов до указанного объекта */
const getExpandedTree = (payload: GetExpandedTreePayload) =>
  api.v4('get', 'area/objects/tree/expanded', { ...payload, show_all_area_types: 1 });

/** Карточка. Получение карточки объекта */
const getCard = (payload: GetCardRequest) => api.v4('get', 'area/get', payload);

/** Карточка. Обновление карточки */
const updateCard = (payload: Partial<UpdateCardRequest>) =>
  api.v4('post', 'area/update', payload);

/** Настройки. Получение настроек */
const getSettings = (payload: GetSettingsRequest) =>
  api.v4('get', 'area/settings/get', payload);

/** Настройки. Сохранение настроек */
const updateSettings = (payload: Settings) =>
  api.v4('post', 'area/settings/update', payload);

/** Создание объекта */
const createObject = (payload: Partial<CreateObjectRequest>) =>
  api.v4('post', 'area/create', payload);

/** Удаление объекта */
const deleteObject = (payload: DeleteObjectRequest) =>
  api.v4('post', 'area/delete', payload);

/** Массовое удаление объектов */
const deleteMultipleObjects = (payload: DeleteObjectsRequest) =>
  api.v4('post', 'area/delete-multiple', payload);

/** Генерация QR */
const createQr = (payload: IObjectsCreateQrRequest) =>
  api.v4('post', 'area/bind-by-qr/create-pdf', {
    responseType: 'arraybuffer',
    ...payload,
  });

/** Обновление аренды */
const updateRent = (payload: UpdateRentPayload) =>
  api.v4('post', 'rent/settings/template', payload);

/** Получение аренды */
const getRent = (payload: GetRentPayload) =>
  api.v4('get', 'rent/settings/template', payload);

/** Создание правил */
const saveRule = (payload: CreateRulePayload) =>
  api.v4('post', 'area-document/create', { ...payload, category_id: 1, status_id: 1 });

/** Редактирование правил */
const updateRule = (payload: UpdateRulePayload) =>
  api.v4('post', 'area-document/update', payload);

/** Просмотр правил */
const getRule = (payload: GetRulePayload) =>
  api.v4('get', 'area-document/list', { ...payload, category_id: 1, status_id: 1 });

/** Экспорт правил */
const exportRule = (payload: ExportRulePayload) =>
  api.v4('get', 'area-document/view', {
    ...{ ...payload, export: 1 },
    ...{ responseType: 'arraybuffer' },
  });

/** Список документов */
const getDocs = (payload: GetDocsPayload) => api.v4('get', 'area-document/list', payload);

/** Получение способов назначения исполнителей */
const getAppointmentAssignees = () =>
  api.v4('get', 'ticket/assignee-appointment-method-list');

/** Список статусов документов */
const getStatusesDocuments = () => api.v4('get', 'area-document/status/list');

/** Загрузка документа */
const uploadDocument = (payload: UploadDocumentPayload) =>
  api.v4('post', 'area-document/upload', {
    ...{
      headers: { 'Content-Type': 'multipart/form-data' },
    },
    ...payload,
  });

/** Экспорт документа */
const exportDocument = (payload: ExportDocPayload) =>
  api.v4('get', 'area-document/view', {
    ...payload,
    ...{ responseType: 'arraybuffer' },
  });

/** Удаление документов */
const deleteDocuments = (payload: DocumentsPayload) =>
  api.v4('post', 'area-document/delete', payload);

/** Архивация документов */
const archiveDocuments = (payload: DocumentsPayload) =>
  api.v4('post', 'area-document/archive', payload);

/** Разархивация документов */
const unarchiveDocuments = (payload: DocumentsPayload) =>
  api.v4('post', 'area-document/unarchive', payload);

/** Поиск по дереву объектов */
const searchObjects = (payload: SearchObjectsPayload) =>
  api.v4('get', 'area/search', payload);

/** Запрос типов помещений */
const getPropertyTypes = (payload: PropertyTypesPayload) =>
  api.v4('get', 'area/type/list', { ...payload, with_client_type: 1 });

export const objectsApi = {
  getObjects,
  getObjectsTable,
  getCard,
  updateCard,
  getSettings,
  updateSettings,
  createObject,
  deleteObject,
  deleteMultipleObjects,
  createQr,
  updateRent,
  getRent,
  saveRule,
  getRule,
  getAppointmentAssignees,
  updateRule,
  exportRule,
  getDocs,
  getStatusesDocuments,
  uploadDocument,
  exportDocument,
  deleteDocuments,
  archiveDocuments,
  unarchiveDocuments,
  searchObjects,
  getExpandedTree,
  getPropertyTypes,
};
