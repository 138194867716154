import { Components } from '@mui/material';

export const Tab: Components['MuiTab'] = {
  styleOverrides: {
    root: {
      textTransform: 'none',
    },
  },
  defaultProps: {
    disableTouchRipple: true,
  },
};
