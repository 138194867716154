import { useGate, useUnit } from 'effector-react';
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  FormLabel,
  TextField,
  CircularProgress,
} from '@mui/material';
import { Listbox } from '@features/effector-form/components/listbox';
import {
  RentTimeProps,
  RentTime,
} from '@features/effector-form/controls/rent-time/types';
import { withControl } from '@features/effector-form/hoc/withControl';
import { RentTimeControlGate, rentTimeQuery, dividerForSeconds } from './model';

export const RentTimeControl = withControl<RentTimeProps>(
  ({
    value = [],
    setValue,
    error,
    label,
    required,
    readOnly,
    disabled,
    rentalDate = null,
    rentObject = null,
  }) => {
    useGate(RentTimeControlGate, {
      rentalDate: rentalDate as Date,
      rentObject: rentObject as string,
    });

    const { data, pending } = useUnit(rentTimeQuery);

    const isExistDate =
      Boolean(rentalDate && new Date(rentalDate).toString() !== 'Invalid Date') &&
      (new Date(rentalDate as Date) as any) / dividerForSeconds > 0;

    const isOptionEqualToValue = (option: RentTime, value: RentTime) =>
      option?.id === value?.id;

    return (
      <FormControl required={required} disabled={disabled}>
        <FormLabel>{label}</FormLabel>
        <Autocomplete
          multiple
          loading={pending}
          value={value}
          options={data && isExistDate && rentObject ? data : []}
          isOptionEqualToValue={isOptionEqualToValue}
          onChange={(_: unknown, newValue: any) => {
            setValue(newValue.map((item: RentTime) => item));
          }}
          ListboxComponent={Listbox}
          renderInput={(params): JSX.Element => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                readOnly,
                endAdornment: readOnly ? null : (
                  <>
                    {pending && <CircularProgress size={20} color="inherit" />}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          readOnly={readOnly}
          disabled={disabled}
        />
        <FormHelperText error>{error}</FormHelperText>
      </FormControl>
    );
  }
);
