import * as React from 'react';
import { FormControl, FormHelperText, FormLabel, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { Listbox } from '@features/effector-form/components/listbox';
import { withControl } from '@features/effector-form/hoc/withControl';
import { SelectControlType, SelectOption } from './types';

export const MultipleSelectControl = withControl<SelectControlType>(
  ({ value, setValue, error, label, required, readOnly, disabled, options, placeholder }) => (
    <FormControl required={required} disabled={disabled}>
      {label ? <FormLabel>{label}</FormLabel> : null}

      <Autocomplete
        multiple
        value={value}
        onChange={(_: unknown, newValue: SelectOption[]) => {
          setValue(newValue.map((item) => item));
        }}
        options={!options ? [] : options}
        ListboxComponent={Listbox}
        disableClearable
        renderInput={(params): JSX.Element => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              readOnly,
              placeholder,
              endAdornment: readOnly ? null : params.InputProps.endAdornment,
            }}
          />
        )}
        readOnly={readOnly}
        disabled={disabled}
      />

      {error ? <FormHelperText error>{error}</FormHelperText> : null}
    </FormControl>
  )
);
