import { createEffect, sample } from 'effector';
import { createGate } from 'effector-react';
import { cache, createQuery, localStorageCache } from '@farfetched/core';
import { signout } from '@features/common';
import { propertyTypesApi } from '@features/effector-form/controls/property-type-control/api';
import {
  PropertyTypePayload,
  PropertyTypeResponse,
  Props,
} from '@features/effector-form/controls/property-type-control/types';

export const PropertyTypesControlGate = createGate<Props>();

const fxGetPropertyTypes = createEffect<
  PropertyTypePayload,
  PropertyTypeResponse,
  Error
>().use(propertyTypesApi.getPropertyTypes);

export const propertyTypesQuery = createQuery({
  effect: fxGetPropertyTypes,
  mapData: ({ result }) =>
    Array.isArray(result?.types)
      ? result.types.map((type) => ({
          id: type.id,
          label: type.title,
          name: type?.parent_type_title ? type.parent_type_title : type.name,
        }))
      : [],
});

cache(propertyTypesQuery, {
  adapter: localStorageCache({ maxAge: '5m' }),
  purge: signout,
});

sample({
  clock: [PropertyTypesControlGate.state, PropertyTypesControlGate.status],
  source: {
    status: PropertyTypesControlGate.status,
    state: PropertyTypesControlGate.state,
  },
  filter: ({ status, state: { onlyActual, parentAreaId } }) => {
    if (status) {
      return onlyActual ? Boolean(parentAreaId) : true;
    }

    return false;
  },
  fn: ({ state: { withClientType, mode, onlyActual, parentAreaId } }) => {
    const payload: PropertyTypePayload = {};

    if (withClientType) {
      payload.with_client_type = Number(withClientType);
    }

    if (mode) {
      payload.mode = mode;
    }

    if (onlyActual && parentAreaId) {
      payload.only_actual = Number(onlyActual);
      payload.parent_area_id = parentAreaId;
    }

    return payload;
  },
  target: propertyTypesQuery.start,
});
