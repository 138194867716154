import { createEffect, sample } from 'effector';
import { createGate } from 'effector-react';
import { cache, createQuery, localStorageCache } from '@farfetched/core';
import { signout } from '@features/common';
import { propertyApi } from './api';
import {
  PropertyControlProps,
  PropertyResponse,
} from './types';

export const PropertyControlGate = createGate<PropertyControlProps>();

export const fxGetList = createEffect<
  any,
  PropertyResponse,
  Error
>().use(propertyApi.getList);

export const propertyQuery = createQuery({
  name: 'property',
  effect: fxGetList,
  mapData: ({ result, params }) =>
    result.items.map((item) => ({
      id: item.id,
      title: item.title,
      buildings: item.buildings,
    })),
});

cache(propertyQuery, {
  adapter: localStorageCache({ maxAge: '5m' }),
  purge: signout,
});

sample({
  clock: PropertyControlGate.state,
  target: propertyQuery.start,
});
