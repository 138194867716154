import Chip from '@mui/material/Chip';
import Close from '@mui/icons-material/Close';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  smallChipRoot: {
    height: '100%',
    display: 'flex',
    padding: '6px 8px',
    backgroundColor: ({ isWarningChip }) => (isWarningChip ? '#FB8901' : '#E7E7EC'),
    '&:focus': {
      backgroundColor: ({ isWarningChip }) => (isWarningChip ? '#FB8901' : '#E7E7EC'),
    },
    '&:hover': {
      backgroundColor: ({ isWarningChip }) => (isWarningChip ? '#FB8901' : '#E7E7EC'),
    },
  },
  smallChipLabel: {
    color: ({ isWarningChip }) => (isWarningChip ? '#FFF' : '#65657B'),
    fontSize: 13,
    fontStyle: 'normal',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 500,
    padding: '0 5px',
    overflowWrap: 'break-word',
    whiteSpace: 'normal',
    textOverflow: 'clip',
  },
});

export const MultiSelectValue = (props) => {
  const { selectProps } = props;

  const isFixed = selectProps.value.find(({ fio }) => fio === props.children)?.isFixed;
  const isDisabled = selectProps.isDisabled || selectProps.mode === 'view' || isFixed;

  const value = props.options.find((option) => {
    if (!selectProps?.escapeSybmols) {
      return option.title === props.children;
    }

    const children = props.children.match(selectProps.escapeSybmols)
      ? props.children.match(selectProps.escapeSybmols)[1].trim()
      : props.children;

    return option.title === children;
  });

  const onClick = () => {
    if (selectProps.mode === 'view' && value?.id && selectProps?.path) {
      window.open(`${window.location.origin}/${selectProps.path}/${value.id}`);
    }
  };

  const isWarningChip = selectProps?.warningIds
    ? Boolean(selectProps.warningIds.find((id) => id === value?.id))
    : false;

  const classes = useStyles({ isWarningChip });

  return (
    <Chip
      classes={{ root: classes.smallChipRoot, label: classes.smallChipLabel }}
      tabIndex={-1}
      sx={{ opacity: isFixed && selectProps.mode === 'edit' ? 0.7 : 1 }}
      label={props.children}
      onDelete={props.removeProps.onClick}
      onClick={onClick}
      deleteIcon={
        <Close
          {...props.removeProps}
          style={{
            fontSize: '1em',
            margin: '0 5px 0 0',
            display: isDisabled ? 'none' : 'block',
            color: isWarningChip && '#FFF',
          }}
        />
      }
    />
  );
};
