import { useStore } from 'effector-react';
import { makeStyles } from '@mui/styles';
import { $userRole } from '@features/common';
import Logo from '@img/ujin.svg';
import { $account } from '../common';
import {
  $isCollapsed,
  $isFixed,
  $menuWithNotificationBadge,
  $showInNav,
  switchMenuCollapse,
} from './models';
import { NavList } from './organisms';

const useStyles = makeStyles({
  navbar: (props) => ({
    position: 'absolute',
    backgroundColor: 'white',
    height: '100vh',
    width: props.isFixed ? 300 : props.isCollapsed ? 75 : 300,
    paddingBottom: 24,
    boxShadow: '0px 4px 15px #e7e7ec',
    zIndex: 1100,
    transition: 'width 0.1s',
  }),
  navbar__content: (props) => ({
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    gap: 15,
    padding: props.isCollapsed ? '16px 0 0 0' : '16px 0 0 0',
  }),
  navbar__logo: {
    display: 'grid',
    margin: '0 auto',
    transform: 'scale(0.7)',
  },
  navbar__footer: {
    display: 'grid',
    gap: 10,
    alignContent: 'end',
    justifyContent: 'center',
  },
  navbar__version: {
    textAlign: 'center',
    opacity: 0.5,
    fontSize: 12,
  },
});

export const Navbar = () => {
  const menu = useStore($menuWithNotificationBadge);
  let showInNav = useStore($showInNav);
  const account = useStore($account);
  const userRole = useStore($userRole);
  const isFixed = useStore($isFixed);
  const isCollapsed = useStore($isCollapsed);
  const isAccountLogo = account.logo !== 'null' && account.logo;
  const styleProps = {
    isFixed,
    isCollapsed,
  };
  const classes = useStyles(styleProps);

  const userAvailableSections = userRole.sections || {};

  showInNav = Object.entries(userAvailableSections)
    .filter(([key, value]) => value)
    .map(([key, value]) => key)
    .filter((section) => showInNav.includes(section));

  const availableSections = menu.items
    .reduce((acc, item) => {
      if (item.nest) {
        const subitems = item.items.filter((subitem) =>
          showInNav.includes(`${subitem.link}`)
        );

        if (subitems.length === 1) {
          return acc.concat({
            icon: item.icon,
            activeIcon: item.activeIcon,
            ...subitems[0],
          });
        }

        return acc.concat({ ...item, items: subitems });
      }
      return showInNav.includes(`${item.link}`) ? acc.concat(item) : acc;
    }, [])
    .filter((item) => (item.nest && item.items.length > 0) || !item.nest);

  const handleMouseEvents = (event) => {
    if (isFixed) return null;

    switch (event.type) {
      case 'mouseenter':
        switchMenuCollapse(false);
        break;

      case 'mouseleave':
        switchMenuCollapse(true);
        break;

      default:
        return null;
    }

    return null;
  };

  return (
    <aside
      className={classes.navbar}
      onMouseEnter={handleMouseEvents}
      onMouseLeave={handleMouseEvents}
    >
      <div className={classes.navbar__content}>
        {isAccountLogo ? (
          <img src={account.logo} width={60} style={{ margin: '0 auto' }} />
        ) : (
          <Logo className={classes.navbar__logo} alt="Ujin" />
        )}
        <NavList items={availableSections !== null ? availableSections : menu.items} />
        <div className={classes.navbar__footer}>
          {isAccountLogo ? <Logo className={classes.navbar__logo} alt="Ujin" /> : null}
          <span className={classes.navbar__version}>{process.env.BUILD_VERSION}</span>
        </div>
      </div>
    </aside>
  );
};
