import axios from 'axios';
import qs from 'qs';
import { signout } from '@features/common';
import i18n from '@shared/config/i18n';
import { getCookie } from '@tools/cookie';
import { newGetFormData as getFormData } from '../tools/newGetFormData';
import ApiV2 from './api_v2/ApiV2';
import ApiToken from './api_v2/Credentials/ApiToken';
import AuthenticatedRequest from './api_v2/Http/AuthenticatedRequest';
import Endpoint from './api_v2/Http/Endpoint';
import FormDataRequest from './api_v2/Http/FormDataRequest';
import JsonRequest from './api_v2/Http/JsonRequest';
import Request from './api_v2/Http/Request';
import ValidatedRequest from './api_v2/Http/ValidatedRequest';
import { getDefaultParams } from './common';
import { getBaseHeaders } from './tools/getBaseHeaders';

const { t } = i18n;

const API_URL = process.env.API_URL;

const checkNetworkError = (response) => {
  if (response.status < 200 || response.status > 299) {
    const errorMessage = response?.data?.message || t('UnexpectedError');

    throw new Error(errorMessage);
  }
};

const checkEncodedDataError = (response) => {
  const decodeData = (data) => {
    try {
      const decoded = JSON.parse(new TextDecoder().decode(data));

      if (Number(decoded.error)) {
        return decoded?.message || t('UnexpectedError');
      }

      return null;
    } catch (error) {
      return null;
    }
  };

  const message = decodeData(response.data);

  if (message) {
    throw new Error(message);
  }
};

// Проверка перед каждым запросом на наличие авторизации.
// Если запрос на авторизацию пропускаем
// В остальных случаях, если нет авторизации, выходим из аккаунта
const checkAuth = (params) => {
  if (
    params &&
    Object.prototype.hasOwnProperty.call(params, 'authRequest') &&
    params.authRequest
  ) {
    return true;
  }

  if (!getCookie('bms_token')) {
    signout();
    return false;
  }
  return true;
};

const getBaseUrl = (url) => (/http?s:\/\//.test(url) ? '' : API_URL);
const validateStatus = (status) => status < 505;

const get = (url, params = {}, signal = undefined) => {
  if (checkAuth(params)) {
    const baseURL = getBaseUrl(url);
    const payload = { ...getDefaultParams(), ...params };
    const queryString = `${url}?${qs.stringify(payload, { encode: false })}`;

    const config = { baseURL, validateStatus, headers: getBaseHeaders() };

    if (signal?.aborted !== undefined) {
      config.signal = signal;
    }

    return axios.get(queryString, config).then((response) => {
      checkNetworkError(response);
      return response;
    });
  }
};

const post = (url, params = {}, options = {}, signal = undefined) => {
  if (checkAuth(params)) {
    const { headers = {} } = options;
    const baseURL = getBaseUrl(url);
    const payload = { ...getDefaultParams(), ...params };

    const transformRequest =
      headers['Content-Type'] === 'application/json'
        ? (data) => JSON.stringify(data)
        : (data) => getFormData(data);

    const config = {
      baseURL,
      transformRequest: [transformRequest],
      validateStatus,
    };

    if (signal?.aborted !== undefined) {
      config.signal = signal;
    }

    config.headers = { ...getBaseHeaders(), ...headers };

    return axios.post(url, payload, config).then((response) => {
      checkNetworkError(response);
      return response;
    });
  }
};

const put = (url, params = {}, options = {}) => {
  if (checkAuth(params)) {
    const { headers = {} } = options;
    const baseURL = getBaseUrl(url);
    const { token } = getDefaultParams();
    const payload = { ...params };

    const transformRequest =
      headers['Content-Type'] === 'application/json'
        ? (data) => JSON.stringify(data)
        : (data) => getFormData(data);

    const config = {
      baseURL,
      transformRequest: [transformRequest],
      validateStatus,
    };

    config.headers = { ...getBaseHeaders(), ...headers };

    return axios.put(`${url}?token=${token}`, payload, config).then((response) => {
      checkNetworkError(response);
      return response;
    });
  }
};

const request = (url, params = {}, options = {}) => {
  const { method = 'post', config = null, data = {} } = options;

  const baseURL = getBaseUrl(url);
  const payload =
    Object.keys(data).length > 0
      ? { ...getDefaultParams(), ...data }
      : { ...getDefaultParams(), ...params };

  const defaultPostConfig = {
    headers: getBaseHeaders(),
    method,
    baseURL,
    url,
    data: payload,
    validateStatus,
    transformRequest: [(data) => getFormData(data)],
  };

  const defaultGetConfig = {
    headers: getBaseHeaders(),
    method,
    validateStatus,
    baseURL,
    url: `${url}?${qs.stringify(payload)}`,
  };

  return axios(
    !config
      ? method.toLowerCase() === 'post'
        ? defaultPostConfig
        : defaultGetConfig
      : method.toLowerCase() === 'post'
      ? Object.assign({}, defaultPostConfig, config)
      : Object.assign({}, defaultGetConfig, config)
  ).then((response) => {
    checkEncodedDataError(response);
    checkNetworkError(response);

    return response;
  });
};

const v2Post = (url, data = {}, params = {}, useJson = true) => {
  if (checkAuth()) {
    const defaultParams = getDefaultParams();

    if (!defaultParams.token) {
      throw new Error('v2Post is not implemented for non authenticated requests');
    }

    const TypedRequest = useJson ? JsonRequest : FormDataRequest;

    const request = new ValidatedRequest(
      new AuthenticatedRequest(
        new TypedRequest(
          new Request(
            new ApiV2(`${API_URL}/v2`, defaultParams.app),
            new Endpoint(url, data, params)
          )
        ),
        new ApiToken(defaultParams.token)
      )
    );

    return request.send().then((response) => {
      return response.data;
    });
  }
};

const v4request = (method, url, data = {}) => {
  if (checkAuth()) {
    const { responseType, headers, ...restData } = data;

    const payload = { ...getDefaultParams(), ...restData };

    const config =
      method.toLowerCase() === 'get'
        ? {
            method,
            validateStatus,
            url: `${url}?${qs.stringify(payload)}`,
          }
        : {
            method,
            validateStatus,
            url,
            data: payload,
          };

    if (
      method.toLowerCase() === 'post' &&
      headers &&
      headers['Content-Type'] === 'multipart/form-data'
    ) {
      config.transformRequest = [(d) => getFormData(d)];
    }

    if (responseType) {
      config.responseType = responseType;
    }

    config.headers = { ...getBaseHeaders(), ...config.headers };

    return axios(config).then((response) => {
      checkEncodedDataError(response);
      checkNetworkError(response);

      return response;
    });
  }
};

export const http = {
  get,
  post,
  put,
  request,
  v2Post,
  v4request,
};
