import { useGate, useUnit } from 'effector-react';
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  FormLabel,
  TextField,
  CircularProgress,
} from '@mui/material';
import { Listbox } from '@features/effector-form/components/listbox';
import {
  PropertyType,
  Props,
} from '@features/effector-form/controls/property-type-control/types';
import { withControl } from '@features/effector-form/hoc/withControl';
import { PropertyTypesControlGate, propertyTypesQuery } from '../model';

export const PropertyTypesControl = withControl<Props>(
  ({
    value,
    setValue,
    error,
    label,
    required,
    readOnly,
    disabled,
    withClientType = false,
    disableClearable = false,
    sx = {},
    mode,
    onlyActual = false,
    parentAreaId = null,
  }) => {
    useGate(PropertyTypesControlGate, { withClientType, mode, onlyActual, parentAreaId });

    const { data, pending } = useUnit(propertyTypesQuery);

    const options = !data ? [] : data;
    const list = onlyActual && !parentAreaId ? [] : options;

    return (
      <FormControl required={required} disabled={disabled} sx={sx}>
        {label && <FormLabel>{label}</FormLabel>}
        <Autocomplete
          disableClearable={disableClearable}
          multiple
          loading={pending}
          value={
            !data
              ? []
              : data.filter(
                  (item) =>
                    value
                      .map((i: PropertyType) => (typeof i === 'object' ? i.id : i))
                      .indexOf(item.id) > -1
                )
          }
          options={list}
          onChange={(_: unknown, newValue: any) => {
            setValue(newValue.map((item: PropertyType) => item));
          }}
          ListboxComponent={Listbox}
          renderInput={(params): JSX.Element => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                readOnly,
                endAdornment: readOnly ? null : (
                  <>
                    {pending && <CircularProgress size={20} color="inherit" />}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          readOnly={readOnly}
          disabled={disabled}
        />
        <FormHelperText error>{error}</FormHelperText>
      </FormControl>
    );
  }
);
